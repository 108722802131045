<template>
  <h1>
    Hello!
  </h1>
  <p>
    My name is Michael James<br />and this is my <br />digital business card<br />
  </p>
  <p>
    I can be reached at <br /><a href="mailto:hello@michaeljames.me"
      >hello@michaeljames.me</a
    >
    <br /><a href="tel:254-350-1702">254-350-1702</a>
  </p>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgb(26, 29, 54);
  font-family: var(--font-family);
  font-weight: 400;
  color: var(--text-color);
}

a {
  color: var(--blue-300);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50; */
  margin-top: 40px;
}

img {
  border-radius: 50%;
  border: 5px solid white;
  max-width: 128px;
}

h1 {
  font-size: 60px;
  margin: 30px auto;
}
h2 {
  font-size: 20px;
}
p {
  font-size: 20px;
}
</style>
